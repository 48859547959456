export const CANCEL = "Cancelar";
export const COMPLETE_REGISTRATION = "Completar Cadastro";
export const CONFIRM = "Confirmar";
export const CREATE_REGISTRATION = "Criar Cadastro";
export const CREATE_REGISTRATION_AND_PLAY = "Criar Conta e Jogar!";
export const CREATE_ROOM_GAME = "Criar Sala de Jogo";
export const JOIN_ROOM = "Entrar na Sala";
export const JOIN_ROOM_GAME = "Entrar Em Uma Sala Para Jogar";
export const KNOW_HOW_TO_PLAY = "Conheça Como Jogar";
export const LOGIN = "Login";
export const LOGIN_WITH_GOOGLE = "Entrar Com o Google";
export const PLAY_AS_GUEST = "Jogar Como Convidado";
export const PLAY_WITH_LOGIN = "Jogar Com Login";
export const START_GAME = "Começar Partida";
