import React from "react";
import { Box } from "@mui/material";
import CustomButton from "../../Buttons/CustomButton";
import { CANCEL, CONFIRM } from "../../../utils/ButtonTexts";

interface ConfirmModalProps {
  handleClose: () => void;
  handleConfirm: () => void;
  description: string;
  buttonLabel?: string;
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 3
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  handleClose,
  handleConfirm,
  description,
  buttonLabel
}) => {
  return (
    <Box sx={modalStyle}>
      <p className="mb-2">{description}</p>
      <Box display="flex" justifyContent="space-between" sx={{ mt: 3 }}>
        <CustomButton
          onClick={handleClose}
          variant="outlined"
          className="text-pink-600 text-button-semibold"
          customStyle={{
            borderColor: "#E73088",
            ":hover": {
              borderColor: "#C9126A",
              backgroundColor: "whitesmoke"
            }
          }}
        >
          {CANCEL}
        </CustomButton>
        <CustomButton
          backgroundColor={"#E73088"}
          customStyle={{
            ":hover": {
              backgroundColor: "#C9126A"
            }
          }}
          onClick={handleConfirm}
        >
          {buttonLabel ?? CONFIRM}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default ConfirmModal;
