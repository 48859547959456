import * as sessionService from "../services/SessionService/SessionService";
import { LoginRequest } from "../services/SessionService/types";

export const login = async (user: LoginRequest) => {
  const response = await sessionService.login(user);
  if (response.status >= 400) throw new Error("Problem with api response");
  if (response.data) {
    const loggedUser = {
      accessToken: response.data.token.accessToken,
      role: "user",
      id: response.data.userId
    };
    sessionStorage.setItem("user", JSON.stringify(loggedUser));
    return loggedUser;
  }
  return Promise.reject(response);
};

export const createPlayerLogin = async (playerName: string) => {
  const response = await sessionService.createPlayerLogin(playerName);
  if (response) {
    const tempLoggedUser = {
      accessToken: response.data.token.accessToken,
      role: "player"
    };
    sessionStorage.setItem("tempUser", JSON.stringify(tempLoggedUser));
    return tempLoggedUser;
  }
  return Promise.reject(response);
};
