export type RoomOptionsInput = {
  label: string;
  placeholder: string;
  name: string;
  type: string;
  error: boolean;
};

export const RoomOptionsInputs: RoomOptionsInput = {
  label: "Nome do Jogador",
  placeholder: "Insira seu nome de jogador",
  name: "email",
  type: "text",
  error: false
};
