import React, { ReactElement } from "react";
import BaseLayout from "../../shared/components/BaseLayout";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import { JOIN_ROOM } from "../../shared/utils/ButtonTexts";
import { ENTER_ROOM } from "../../shared/utils/PagesTitles";
import OtpInput from "../../shared/components/Inputs/OTP";
import { handleKeyDown } from "../../shared/helpers/Functions";
import { FormControl } from "@mui/material";

interface EnterRoomContentProps {
  handleClickJoinRoom: () => void;
  handleInputChange: (value: string) => void;
  handlePaste: (e: React.ClipboardEvent<HTMLDivElement>) => void;
  playerName: string;
  seed: string;
  isJoiningRoom: boolean;
  isButtonDisabled: boolean;
}

function EnterRoomContent({
  handleClickJoinRoom,
  handleInputChange,
  handlePaste,
  playerName,
  seed,
  isJoiningRoom,
  isButtonDisabled
}: EnterRoomContentProps): ReactElement {
  return (
    <BaseLayout justifyContent="justify-start">
      <FormControl onKeyDown={(e) => handleKeyDown(e, handleClickJoinRoom)}>
      <div className="flex flex-col items-center justify-between min-h-80 mt-20">
        <h4 className="text-body-bold text-white">{playerName}</h4>
        <div className="flex flex-col items-center justify-center">
          <h4 className="text-button-regular text-white mb-6">{ENTER_ROOM}</h4>
          <OtpInput value={seed} handleChange={handleInputChange} handlePaste={handlePaste} />
          <CustomButton
            textColor="white"
            size="large"
            customStyle={{ margin: 2 }}
            className="w-full bg-pink-500 hover:bg-pink-600 mt-10"
            loading={isJoiningRoom}
            disabled={isButtonDisabled}
            onClick={handleClickJoinRoom}
          >
            {JOIN_ROOM}
          </CustomButton>
        </div>
      </div>
      </FormControl>
    </BaseLayout>
  );
}

export default EnterRoomContent;
