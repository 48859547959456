import React, { ReactNode } from "react";

interface BaseLayoutProps {
  children: ReactNode;
  justifyContent?: string;
}

export default function BaseLayout({ children, justifyContent }: BaseLayoutProps): JSX.Element {
  return (
    <main
      className={`${justifyContent ?? "justify-around"} flex flex-col bg-blue-100 bg-cover bg-center items-center min-h-[calc(100vh-4rem)] h-auto `}
    >
      <div className="flex flex-col w-80 mdMax:w-11/12">{children}</div>
    </main>
  );
}
