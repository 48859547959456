import React, { ReactElement } from "react";
import { Tooltip, styled, TooltipProps } from "@mui/material";

interface CustomTooltipProps extends TooltipProps {
  title: string;
  children: ReactElement;
}

const CustomTooltip = styled(({ className, ...props }: CustomTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  "& .MuiTooltip-tooltip": {
    fontFamily: "Dosis, sans-serif",
    fontSize: "14px",
    backgroundColor: "#333",
    color: "white",
  },
}));

export default CustomTooltip;
