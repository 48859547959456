import React, { ReactElement, useEffect, useState } from "react";
import BaseLayout from "../../shared/components/BaseLayout";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import { START_GAME } from "../../shared/utils/ButtonTexts";
import Badge from "../../shared/components/Badge";
import { useNavigate } from "react-router-dom";
import { ROOM_CODE, ROOM_START_INSTRUCTION, ROOM_WAIT_INSTRUCTIONS } from "./utils/LobbyTexts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CustomTooltip from "../../shared/components/Tooltip";
import RoomPlayersManager from "./components/RoomPlayersManager";
import { CreateRoomResponse, Player } from "../../DTOs/Player";

interface LobbyContentProps {
  room: CreateRoomResponse | null;
  playersIn: Player[];
  playersOut: Player[];
  isLoadingRoom: boolean;
}

function LobbyContent({
  room,
  isLoadingRoom,
  playersIn,
  playersOut
}: LobbyContentProps): ReactElement {
  const [tooltipTitle, setTooltipTitle] = useState<string>("Copiar código");
  const navigate = useNavigate();

  function copySeedToClipboard(): void {
    navigator.clipboard.writeText(room?.seed ?? "").then(() => {
      setTooltipTitle("Código copiado!");
    });
  }

  function handleMouseLeave(): void {
    setTooltipTitle("Copiar código");
  }

  useEffect(() => {
    if (!room && !isLoadingRoom) navigate("/opcoes-de-sala");
  }, [isLoadingRoom, navigate, room]);

  return (
    <BaseLayout>
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-row items-center p-5">
          <h4 className="text-body-bold text-white mr-4">{room?.player?.playerName}</h4>
          {room?.player.isAdmin && <Badge text="admin da sala" />}
        </div>
        <div className="flex flex-row items-center px-5">
          {room?.player?.isAdmin ? (
            <div className="flex flex-col items-center justify-center">
              <h4 className="text-body-regular text-white mr-4">
                {ROOM_CODE}{" "}
                <span className="text-body-bold text-pink-500 ml-1">
                  {room?.seed}
                  <button onClick={copySeedToClipboard} onMouseLeave={handleMouseLeave}>
                    <CustomTooltip title={tooltipTitle}>
                      <ContentCopyIcon
                        fontSize="small"
                        className="text-white ml-2 cursor-pointer"
                        sx={{ fontSize: "16px" }}
                      />
                    </CustomTooltip>
                  </button>
                </span>
              </h4>
              <h5 className="text-body-regular mt-4 text-white text-center">
                {ROOM_START_INSTRUCTION}
              </h5>
            </div>
          ) : (
            <h5 className="text-body-regular text-white text-center">{ROOM_WAIT_INSTRUCTIONS}</h5>
          )}
        </div>
        <RoomPlayersManager playersIn={playersIn} playersOut={playersOut} />
        {room?.player?.isAdmin && (
          <CustomButton
            textColor="white"
            size="large"
            customStyle={{ margin: 2 }}
            className="w-full bg-pink-500 hover:bg-pink-600"
          >
            {START_GAME}
          </CustomButton>
        )}
      </div>
    </BaseLayout>
  );
}

export default LobbyContent;
