import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { StyledInput, StyledSelect } from "./styles";
import { FormHelperText, SelectChangeEvent } from "@mui/material";

interface CustomSelectProps {
  label?: string;
  name?: string;
  value?: string;
  error?: boolean;
  helperText?: string;
  options: string[] | undefined;
  onChange: (event: SelectChangeEvent<unknown>) => void;
}

export default function CustomSelect({
  label,
  name,
  value,
  options,
  error,
  helperText,
  onChange
}: CustomSelectProps) {
  return (
    <>
      <InputLabel
        shrink
        sx={{ fontFamily: "Dosis, sans-serif" }}
        htmlFor="custom-select"
        className="text-white text-heading-bold"
      >
        {label}
      </InputLabel>
      <StyledSelect
        error
        value={value}
        name={name}
        onChange={onChange}
        input={<StyledInput id="custom-select" />}
      >
        {options?.map((option, index) => (
          <MenuItem key={index} value={option} sx={{ fontFamily: "Dosis, sans-serif" }}>
            {option}
          </MenuItem>
        ))}
        {error && <FormHelperText error>{helperText}</FormHelperText>}
      </StyledSelect>
    </>
  );
}
