import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

type NotificationContextType = {
  notifySuccess: (message: string) => void;
  notifyError: (message: string) => void;
  notifyInfo: (message: string) => void;
};

const NotificationContext = React.createContext<NotificationContextType | undefined>(undefined);

const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState<"success" | "error" | "info">("success");

  const notifySuccess = (msg: string) => {
    setMessage(msg);
    setSeverity("success");
    setOpen(true);
  };

  const notifyError = (msg: string) => {
    setMessage(msg);
    setSeverity("error");
    setOpen(true);
  };

  const notifyInfo = (msg: string) => {
    setMessage(msg);
    setSeverity("info");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const defineAlertClass = (severity: string): string => {
    switch (severity) {
      case "success":
        return "bg-green-100 border-green-300 text-green-300";

      case "error":
        return "bg-red-100 border-red-500 text-red-500";

      case "info":
        return "bg-gray-300 border-blue-800 text-blue-800";

      default:
        return "";
    }
  };

  return (
    <NotificationContext.Provider value={{ notifySuccess, notifyError, notifyInfo }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          className={`w-full border ${defineAlertClass(severity)} font-medium`}
          sx={{ fontFamily: "Dosis, sans-serif" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }
  return context;
};

export default NotificationProvider;
