import * as React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { StyledTextField } from "./styles";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";

interface CustomTextFieldProps {
  placeholder?: string;
  name?: string;
  value?: string;
  type?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customStyle?: SxProps<Theme>;
}

export default function CustomTextField({
  placeholder,
  name,
  value,
  type = "text",
  error = false,
  helperText,
  onChange,
  customStyle
}: CustomTextFieldProps) {
  return (
    <>
      <StyledTextField
        error={error}
        placeholder={placeholder}
        size="medium"
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        id="custom-input"
        sx={{ ...customStyle }}
      />
      <FormHelperText
        error={error}
        sx={{ fontFamily: "Dosis, sans-serif" }}
        className={error ? "text-red" : "text-white"}
      >
        {helperText}
      </FormHelperText>
    </>
  );
}
