import httpClient from "../../hooks/httpClient";
import { CreateUserRequest, CompleteRegisterRequest } from "./types";

export const getUser = (id: string) => httpClient.get(`/users/${id}`);

export const getUserByEmail = (email: string) => httpClient.get(`/users/email/${email}`);

export const createUser = (user: CreateUserRequest) => httpClient.post(`auth/register`, user);

export const completeRegister = (user: CompleteRegisterRequest, id: string) => httpClient.put(`users/update/social/${id}`, user);
