export type RegisterInput = {
  label: string;
  placeholder: string;
  name: string;
  type: string;
  helperText?: string;
  error: boolean;
  options?: string[];
};

export const registerInputs: RegisterInput[] = [
  {
    label: "Nome Completo",
    placeholder: "Nome Completo",
    name: "name",
    type: "text",
    error: false
  },
  {
    label: "Email",
    placeholder: "exemplo@dominio.com.br",
    name: "email",
    type: "email",
    error: false
  },
  {
    label: "Gênero",
    placeholder: "Selecione seu gênero",
    name: "gender",
    type: "select",
    options: ["Feminino", "Masculino", "Outro"],
    error: false
  },
  {
    label: "Ano de Nascimento",
    placeholder: "XXXX",
    name: "birthdate_year",
    type: "number",
    error: false
  },
  {
    label: "País",
    placeholder: "Nome do País",
    name: "country",
    type: "text",
    error: false
  },
  {
    label: "Estado",
    placeholder: "Nome do Estado",
    name: "state",
    type: "text",
    error: false
  },
  {
    label: "Cidade",
    placeholder: "Nome da Cidade",
    name: "city",
    type: "text",
    error: false
  },
  {
    label: "Senha",
    placeholder: "*****",
    name: "password",
    type: "password",
    helperText: "Mínimo 6 caracteres",
    error: false
  },
  {
    label: "Confirmar Senha",
    placeholder: "*****",
    name: "confirm_password",
    type: "password",
    error: false
  }
];

export const completeRegisterInputs: RegisterInput[] = [
  {
    label: "Gênero",
    placeholder: "Selecione seu gênero",
    name: "gender",
    type: "select",
    options: ["Feminino", "Masculino", "Outro"],
    error: false
  },
  {
    label: "Ano de Nascimento",
    placeholder: "XXXX",
    name: "birthdate_year",
    type: "number",
    error: false
  },
  {
    label: "País",
    placeholder: "Nome do País",
    name: "country",
    type: "text",
    error: false
  },
  {
    label: "Estado",
    placeholder: "Nome do Estado",
    name: "state",
    type: "text",
    error: false
  },
  {
    label: "Cidade",
    placeholder: "Nome da Cidade",
    name: "city",
    type: "text",
    error: false
  },
];
