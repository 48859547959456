import { UserDTO } from "../DTOs/User";
import { CreateUserRequest, CompleteRegisterRequest } from "../services/UserService/types";
import * as UserService from "../services/UserService/UserService";

export const getUser = async (id: string) => {
  return await UserService.getUser(id);
};

export const getUserByEmailToLogin = async (
  email: string,
  accessToken: string
): Promise<UserDTO> => {
  const loggedUser = {
    accessToken: accessToken,
    role: "user"
  };
  sessionStorage.setItem("user", JSON.stringify(loggedUser));
  const response = await UserService.getUserByEmail(email);
  if (response.data) {
    return response.data;
  } else return Promise.reject(response);
};

export const createUser = async (user: CreateUserRequest) => {
  const response = await UserService.createUser(user);
  const loggedUser = {
    accessToken: response.data.token.accessToken,
    id: response.data.userId,
    role: "user"
  };
  sessionStorage.setItem("user", JSON.stringify(loggedUser));
  return loggedUser;
};

export const completeRegister = async (user: CompleteRegisterRequest, id: string) => {
  await UserService.completeRegister(user, id);
};
