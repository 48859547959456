import * as React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { StyledPasswordInput } from "./styles";
import { InputLabel } from "@mui/material";

interface PasswordInputProps {
  placeholder?: string;
  name?: string;
  label?: string;
  value?: string;
  error?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function PasswordInput({
  placeholder,
  name,
  label,
  value,
  error = false,
  onChange
}: PasswordInputProps) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <InputLabel
        shrink
        sx={{ fontFamily: "Dosis, sans-serif", position: 'relative', zIndex: 1 }}
        htmlFor="password-input"
        className="text-white text-heading-bold"
      >
        {label}
      </InputLabel>
      <StyledPasswordInput
        id="password-input"
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
}
