import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
  AxiosRequestHeaders
} from "axios";

const URL_BASE = process.env.REACT_APP_API_URL;

const httpClient = axios.create({
  baseURL: URL_BASE
});

interface UserToken {
  accessToken: string;
}

const requestHandler = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = sessionStorage.getItem("user");
  if (token) {
    const user: UserToken = JSON.parse(token);
    if (request.headers) {
      (request.headers as AxiosRequestHeaders).Authorization = `Bearer ${user.accessToken}`;
    } else {
      request.headers = { Authorization: `Bearer ${user.accessToken}` } as AxiosRequestHeaders;
    }
  }
  return request;
};

httpClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const token = sessionStorage.getItem("user");
    if (error.response?.status === 403 && token) {
      sessionStorage.removeItem("user");
      window.location.href = "/";
    }
    return Promise.reject(error.response);
  }
);

httpClient.interceptors.request.use((request: InternalAxiosRequestConfig) =>
  requestHandler(request)
);

export default httpClient;
