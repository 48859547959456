import React, { ReactElement, SetStateAction } from "react";
import BaseLayout from "../../shared/components/BaseLayout";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import { CREATE_ROOM_GAME, JOIN_ROOM_GAME } from "../../shared/utils/ButtonTexts";
import { RoomOptionsInputs } from "./utils/RoomOptionsInput";
import CustomTextField from "../../shared/components/Inputs/CustomTextField";

interface RoomOptionsContentProps {
  handleClickCreateRoom: () => void;
  handleClickJoinRoom: () => void;
  playerName: string;
  setPlayerName: React.Dispatch<SetStateAction<string>>;
  isCreateRoomLoading: boolean;
  isCreatePlayerLoading: boolean;
}

function RoomOptionsContent({
  handleClickCreateRoom,
  handleClickJoinRoom,
  playerName,
  setPlayerName,
  isCreateRoomLoading,
  isCreatePlayerLoading
}: RoomOptionsContentProps): ReactElement {
  return (
    <BaseLayout>
      <CustomTextField
        placeholder={RoomOptionsInputs.placeholder}
        customStyle={{ margin: 2 }}
        value={playerName || ""}
        onChange={(e) => setPlayerName(e.target.value)}
      />
      <CustomButton
        textColor="white"
        size="large"
        customStyle={{ margin: 2 }}
        className="bg-pink-500 hover:bg-pink-600"
        onClick={handleClickCreateRoom}
        loading={isCreateRoomLoading}
        disabled={isCreateRoomLoading || isCreatePlayerLoading}
      >
        {CREATE_ROOM_GAME}
      </CustomButton>
      <CustomButton
        textColor="white"
        size="large"
        customStyle={{ margin: 2 }}
        className="bg-pink-500 hover:bg-pink-600"
        onClick={handleClickJoinRoom}
        disabled={isCreateRoomLoading || isCreatePlayerLoading}
        loading={isCreatePlayerLoading}
      >
        {JOIN_ROOM_GAME}
      </CustomButton>
    </BaseLayout>
  );
}

export default RoomOptionsContent;
