import React, { useEffect, useState } from "react";
import LobbyContent from "./LobbyContent";
import { useGameRoom } from "../../providers/room";
import { useSocket } from "../../providers/socket";
import {
  ADMIN_DISCONNECTION,
  NEW_PLAYER,
  PLAYER_DISCONNECTED,
  ROOM_CHANGES,
  SWITCH_ADMIN_PLAYER
} from "../../shared/utils/SocketEvents";
import ConfirmModal from "../../shared/components/Modals/ConfirmModal";
import { CONFIRM_LEAVE_ROOM } from "./utils/LobbyTexts";
import GenericModal from "../../shared/components/Modals/GenericModal";

export default function Lobby(): JSX.Element {
  const {
    room,
    isLoadingRoom,
    addPlayerIn,
    movePlayerOut,
    switchAdminPlayer,
    leaveRoom,
    playersIn,
    playersOut
  } = useGameRoom();
  const { socket } = useSocket();
  const [open, setOpen] = useState(false);

  const modalContent = (
    <ConfirmModal
      handleClose={() => setOpen(false)}
      handleConfirm={() => {}}
      description={CONFIRM_LEAVE_ROOM}
    />
  );

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    const handleUnload = () => {
      leaveRoom();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [leaveRoom]);

  useEffect(() => {
    if (socket) {
      const handleChanges = (data: any) => {
        const { error } = data;
        if (!error && room?.seed === data.seed) {
          switch (data.type) {
            case NEW_PLAYER:
              addPlayerIn(data.currentPlayer);
              break;

            case PLAYER_DISCONNECTED:
              movePlayerOut(data);
              break;

            case SWITCH_ADMIN_PLAYER:
              switchAdminPlayer(data);
              break;

            case ADMIN_DISCONNECTION:
              movePlayerOut(data);
              break;
          }
        }
      };

      socket.on(ROOM_CHANGES, handleChanges);

      return () => {
        socket.off(ROOM_CHANGES, handleChanges);
      };
    }
  }, [addPlayerIn, movePlayerOut, room?.seed, switchAdminPlayer, socket]);

  return (
    <>
      <LobbyContent
        room={room}
        isLoadingRoom={isLoadingRoom}
        playersIn={playersIn}
        playersOut={playersOut}
      />
      <GenericModal handleClose={() => setOpen(false)} open={open} children={modalContent} />
    </>
  );
}
