import React, { JSXElementConstructor, ReactElement } from "react";
import { Modal, CircularProgress, Box } from "@mui/material";

interface GenericModalProps {
  open: boolean;
  handleClose: () => void;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  isLoading?: boolean;
}

export const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 330,
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 3
};

const GenericModal: React.FC<GenericModalProps> = ({
  handleClose,
  open,
  children,
  isLoading = false
}) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="admin-transfer-modal">
      <Box sx={modalStyle}>
        {isLoading ? (
          <Box className="flex flex-row">
            <CircularProgress size={24} style={{ color: "#E73088" }} />
            <p className="ml-3">Carregando...</p>
          </Box>
        ) : (
          children
        )}
      </Box>
    </Modal>
  );
};

export default GenericModal;
