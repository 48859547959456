import { Socket } from "socket.io-client";
import { CreatePlayerRequest } from "../../../DTOs/Player";
import { useGameRoom } from "../../../providers/room";
import { useSocket } from "../../../providers/socket";
import { useState } from "react";
import { ROOM_ADMIN, ROOM_CHANGES } from "../../../shared/utils/SocketEvents";

export const useRoomService = () => {
  const { socket, initializeSocket } = useSocket();
  const { setRoom } = useGameRoom();
  const [error] = useState<string | null>(null);

  const ensureSocketInitialized = (): Promise<Socket> => {
    return new Promise((resolve, reject) => {
      if (socket?.connected) {
        resolve(socket);
      } else {
        initializeSocket()
          .then((initializedSocket) => {
            if (initializedSocket?.connected) {
              resolve(initializedSocket);
            } else {
              reject(new Error("Failed to initialize socket"));
            }
          })
          .catch((error) => {
            reject(error);
          });

        setTimeout(() => {
          if (!socket?.connected) {
            reject(new Error("Timeout: Socket connection failed"));
          }
        }, 10000);
      }
    });
  };

  const createRoom = async (player: CreatePlayerRequest): Promise<any> => {
    try {
      const socketInitialized = await ensureSocketInitialized();
      return new Promise((resolve, reject) => {
        if (socketInitialized) {
          const handleResponse = (response: any) => {
            socketInitialized.off(ROOM_CHANGES, handleResponse);
            const { error } = response;
            if (!error) {
              const room = {
                id: response.id,
                seed: response.seed,
                player: response.players[0]
              };
              sessionStorage.setItem("room", JSON.stringify(room));
              setRoom(room);
              resolve(response);
            } else reject(response);
          };

          socketInitialized.on(ROOM_CHANGES, handleResponse);

          socketInitialized.emit(ROOM_ADMIN, player);

          setTimeout(() => {
            socketInitialized.off(ROOM_CHANGES, handleResponse);
            reject(new Error("Timeout: No response received"));
          }, 30000);
        }
      });
    } catch (error) {
      return Promise.reject(new Error("Failed to initialize socket"));
    }
  };

  return { createRoom, error };
};
