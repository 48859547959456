import React, { useState } from "react";
import RegisterContent from "./RegisterContent";
import { registerInputs, completeRegisterInputs } from "./utils/RegisterInputs";
import { SelectChangeEvent } from "@mui/material";
import * as UserManager from "../../managers/UserManager";
import { CreateUserRequest, CompleteRegisterRequest } from "../../services/UserService/types";
import { useNotification } from "../../providers/notify";
import { successMessages, errorMessages } from "../../shared/utils/NotifyMessages";
import { useAuth } from "../../providers/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiErrors } from "../../shared/utils/Errors";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const yearRegex = /^\d{4}$/;

export default function Register(): JSX.Element {
  const location = useLocation();
  const { isCompleteRegister } = location.state || false;
  const inputsToRender = isCompleteRegister ? completeRegisterInputs : registerInputs;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { notifySuccess, notifyError } = useNotification();
  const navigate = useNavigate();
  const { setSession, user } = useAuth();
  const [formValues, setFormValues] = useState(
    inputsToRender.reduce((acc, input) => {
      acc[input.name] = "";
      return acc;
    }, {} as Record<string, string>)
  );

  const [errors, setErrors] = useState<Record<string, string>>({});

  function validateField(name: string, value: string) {
    const validators: { [key: string]: (value: string) => boolean } = {
      email: (value) => emailRegex.test(value),
      birthdate_year: (value) => yearRegex.test(value),
      password: (value) => value.length >= 6,
      confirm_password: (value) => value === formValues["password"]
    };

    if (name === "email" || name === "birthdate_year") {
      if (!validators[name](value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessages.INVALID_VALUE
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: ""
        }));
      }
    } else if (name === "password") {
      if (!validators[name](value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessages.MIN_PASSWORD_DIGITS
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: ""
        }));
      }
    } else if (name === "confirm_password") {
      if (!validators[name](value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessages.PASSWORDS_DO_NOT_MATCH
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: ""
        }));
      }
    }
  }

  function validateAllFields(formData: { [key: string]: any }) {
    const newErrors: Record<string, string> = {};
    inputsToRender.forEach((input) => {
      if (!formData[input.name]) {
        newErrors[input.name] = errorMessages.FILL_IN_ALL_FIELDS;
      }
    });
    return newErrors;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ""
    }));

    validateField(name, value);
  };

  const createUser = async () => {
    const userToInsert: CreateUserRequest = {
      name: formValues.name,
      email: formValues.email,
      password: formValues.password,
      birthdateYear: Number(formValues.birthdate_year),
      role: "user",
      gender: formValues.gender,
      country: formValues.country,
      state: formValues.state,
      city: formValues.city
    };

    try {
      const response = await UserManager.createUser(userToInsert);
      notifySuccess(successMessages.USER_CREATED_SUCCESSFULLY);
      setSession(response);
      navigate("/opcoes-de-sala");
    } catch (err: any) {
      if (err?.data?.message === ApiErrors.USER_ALREADY_EXIST)
        notifyError(errorMessages.USER_ALREADY_EXISTS);
      else notifyError(errorMessages.USER_CREATION_FAILED);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = async () => {
    const userToUpdate: CompleteRegisterRequest = {
      birthdateYear: Number(formValues.birthdate_year),
      role: "user",
      gender: formValues.gender,
      country: formValues.country,
      state: formValues.state,
      city: formValues.city
    };

    try {
      if (user) {
        await UserManager.completeRegister(userToUpdate, user.id);
        notifySuccess(successMessages.USER_UPDATED_SUCCESSFULLY);
        navigate("/opcoes-de-sala");
      } else {
        throw new Error("Falha ao identificar usuário");
      }
    } catch (err: any) {
      notifyError(errorMessages.USER_UPDATE_FAILED);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const allErrors = validateAllFields(formValues);
    setErrors(allErrors);

    const hasError = Object.values(allErrors).some((error) => error !== "");
    if (!hasError) {
      setIsLoading(true);
      if (isCompleteRegister) updateUser();
      else createUser();
    }
  };

  return (
    <RegisterContent
      formValues={formValues}
      handleInputChange={handleInputChange}
      registerInputs={registerInputs}
      errors={errors}
      handleSubmit={handleSubmit}
      loading={isLoading}
      isCompleteRegister={isCompleteRegister}
      completeRegisterInputs={completeRegisterInputs}
    />
  );
}
