import React, { useState } from "react";
import EnterRoomContent from "./EnterRoomContent";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessages } from "../../shared/utils/NotifyMessages";
import { useNotification } from "../../providers/notify";
import { JoinRoomRequest } from "../../DTOs/Player";
import { useEnterRoom } from "./hooks/useEnterRoom";

export default function EnterRoom(): JSX.Element {
  const navigate = useNavigate();
  const { joinRoom } = useEnterRoom();
  const { notifyError } = useNotification();
  const location = useLocation();
  const { playerName, userId } = location.state || {};
  const [seed, setSeed] = useState<string>("");
  const [isJoiningRoom, setIsJoiningRoom] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const validateSeed = (value: string) => {
    if (value !== "" && value.length >= 7) return true;
    return false;
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedText = e.clipboardData.getData("Text");
    setSeed(pastedText);
    if (validateSeed(pastedText)) setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };

  const handleInputChange = (value: string) => {
    setSeed(value);
    if (validateSeed(value)) setIsButtonDisabled(false);
    else setIsButtonDisabled(true);
  };

  const handleClickJoinRoom = async () => {
    if (!validateSeed(seed)) {
      notifyError(errorMessages.INVALID_PLAYER_NAME);
      return;
    }

    const player: JoinRoomRequest = {
      playerName: playerName,
      userId: userId ?? undefined,
      seed: seed?.toUpperCase()
    };

    try {
      setIsJoiningRoom(true);
      await joinRoom(player);
      navigate("/sala-de-espera");
    } catch ({ error }: any) {
      if (error === "Seed not Exists") notifyError(errorMessages.INVALID_SEED);
      else notifyError(errorMessages.JOIN_ROOM_FAILED);
    } finally {
      setIsJoiningRoom(false);
    }
  };
  return (
    <EnterRoomContent
      handleClickJoinRoom={handleClickJoinRoom}
      handleInputChange={handleInputChange}
      handlePaste={handlePaste}
      playerName={playerName}
      seed={seed}
      isJoiningRoom={isJoiningRoom}
      isButtonDisabled={isButtonDisabled}
    />
  );
}
