import React, { ReactElement } from "react";
import CustomButton from "../../shared/components/Buttons/CustomButton";
import googleIcon from "../../assets/icons/google.svg";
import { useNavigate } from "react-router-dom";
import { CREATE_REGISTRATION, LOGIN, LOGIN_WITH_GOOGLE } from "../../shared/utils/ButtonTexts";
import CustomTextField from "../../shared/components/Inputs/CustomFormInput";
import { FormControl } from "@mui/material";
import { loginInputs } from "./utils/LoginInputs";
import PasswordInput from "../../shared/components/Inputs/PasswordInput";
import BaseLayout from "../../shared/components/BaseLayout";
import { handleKeyDown } from "../../shared/helpers/Functions";

interface LoginContentProps {
  formValues: Record<string, string>;
  errors: Record<string, string>;
  handleSubmit: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
}

function LoginContent({
  formValues,
  errors,
  handleInputChange,
  handleSubmit,
  isLoading
}: LoginContentProps): ReactElement {
  const navigate = useNavigate();
  return (
    <BaseLayout>
      <FormControl onKeyDown={(e) => handleKeyDown(e, handleSubmit)}>
        {loginInputs.map((input, index) => (
          <div key={index} className="w-full px-4 mb-4">
            <FormControl variant="standard" className="w-full">
              {input.type !== "password" ? (
                <CustomTextField
                  label={input.label}
                  placeholder={input.placeholder}
                  name={input.name}
                  value={formValues[input.name] || ""}
                  type={input.type}
                  onChange={
                    handleInputChange as (event: React.ChangeEvent<HTMLInputElement>) => void
                  }
                  error={!!errors[input.name]}
                />
              ) : (
                <PasswordInput
                  label={input.label}
                  placeholder={input.placeholder}
                  name={input.name}
                  value={formValues[input.name] || ""}
                  onChange={
                    handleInputChange as (event: React.ChangeEvent<HTMLInputElement>) => void
                  }
                  error={!!errors[input.name]}
                />
              )}
            </FormControl>
          </div>
        ))}
        <CustomButton
          textColor="white"
          size="large"
          customStyle={{ margin: 2 }}
          loading={isLoading}
          className="bg-pink-500 hover:bg-pink-600"
          onClick={handleSubmit}
        >
          {LOGIN}
        </CustomButton>
        <CustomButton
          textColor="white"
          size="large"
          customStyle={{ margin: 2 }}
          className="bg-pink-500 hover:bg-pink-600"
          onClick={() => navigate("/cadastro")}
        >
          {CREATE_REGISTRATION}
        </CustomButton>
        <CustomButton
          textColor="white"
          size="large"
          customStyle={{ margin: 2 }}
          className="bg-white text-black font-bold hover:bg-gray-100"
          iconSrc={googleIcon}
          iconAlt="google-icon"
          onClick={() => (window.location.href = process.env.REACT_APP_AUTH_GOOGLE || "")}
        >
          {LOGIN_WITH_GOOGLE}
        </CustomButton>
      </FormControl>
    </BaseLayout>
  );
}

export default LoginContent;
