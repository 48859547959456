export type LoginInput = {
  label: string;
  placeholder: string;
  name: string;
  type: string;
  error: boolean;
};

export const loginInputs: LoginInput[] = [
  {
    label: "Email",
    placeholder: "exemplo@dominio.com.br",
    name: "email",
    type: "email",
    error: false
  },
  {
    label: "Senha",
    placeholder: "*****",
    name: "password",
    type: "password",
    error: false
  },
];
