import React, { useCallback, useEffect, useState } from "react";
import RoomOptionsContent from "./RoomOptionsContent";
import { useAuth } from "../../providers/auth";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../shared/utils/NotifyMessages";
import { useNotification } from "../../providers/notify";
import { useRoomService } from "./hooks/useRoomService";
import { CreatePlayerRequest } from "../../DTOs/Player";
import * as SessionManager from "../../managers/SessionManager";
import * as UserManager from "../../managers/UserManager";

export default function RoomOptions(): JSX.Element {
  const {
    isAuthenticated,
    isTempUserAuthenticated,
    user,
    setTempSession,
    setSession,
    setLoading,
    loading
  } = useAuth();
  const { notifyError } = useNotification();
  const [playerName, setPlayerName] = useState<string>("");
  const [isCreateRoomLoading, setIsCreateRoomLoading] = useState<boolean>(false);
  const [isCreatePlayerLoading, setIsCreatePlayerLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { createRoom } = useRoomService();

  function getAccessTokenFromCookie() {
    function getCookieValue(cookieName: string) {
      const cookies = document.cookie.split("; ");
      const cookie = cookies.find((c) => c.startsWith(cookieName + "="));
      return cookie ? cookie.split("=")[1] : null;
    }

    const encodedToken = getCookieValue("token");

    if (encodedToken) {
      try {
        const decodedValue = decodeURIComponent(encodedToken);

        const tokenObj = JSON.parse(decodedValue.slice(2));

        return tokenObj.accessToken;
      } catch (error) {
        console.error("Erro ao processar o token:", error);
        return null;
      }
    } else {
      return null;
    }
  }

  function getEmailFromJWT(token: string) {
    const parts = token.split(".");

    if (parts.length !== 3) {
      throw new Error("Invalid token format");
    }

    const payload = parts[1];

    const base64 = payload.replace(/-/g, "+").replace(/_/g, "/");
    const decodedPayload = atob(base64);

    const jsonPayload = JSON.parse(decodedPayload);

    return jsonPayload.email || null;
  }

  const getUserByEmailToLogin = useCallback(
    async (userEmail: string, accessToken: string) => {
      try {
        const response = await UserManager.getUserByEmailToLogin(userEmail, accessToken);
        if (response) {
          const loggedUser = {
            accessToken: accessToken,
            id: response.id.toString(),
            role: "user"
          };
          setSession(loggedUser);
          if (!response.city && !response.birthdateYear) {
            navigate("/cadastro", { state: { isCompleteRegister: true } });
          }
        }
      } catch (error) {
        notifyError(errorMessages.GOOGLE_LOGIN_FAILED);
        navigate("/login");
      }
    },
    [navigate, notifyError, setSession]
  );

  useEffect(() => {
    const initialize = async () => {
      if (loading) return;
      setLoading(true);
      sessionStorage.removeItem("room");
      const accessToken = getAccessTokenFromCookie();

      if (accessToken && !isAuthenticated) {
        const userEmail = getEmailFromJWT(accessToken);
        if (userEmail) {
          await getUserByEmailToLogin(userEmail, accessToken);
        } else {
          throw new Error("Falha ao extrair email do token");
        }
      }

      setLoading(false);
    };

    initialize();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, navigate, loading]);

  const validatePlayerName = () => {
    return playerName.length > 0;
  };

  const handleClickCreateRoom = async () => {
    if (!isAuthenticated) return navigate("/login");
    if (!validatePlayerName()) {
      notifyError(errorMessages.INVALID_PLAYER_NAME);
      return;
    }

    const player: CreatePlayerRequest = {
      playerName,
      isAdmin: true,
      userId: user?.id ?? undefined
    };

    try {
      setIsCreateRoomLoading(true);
      await createRoom(player);
      navigate("/sala-de-espera");
    } catch (error) {
      notifyError(errorMessages.ROOM_CREATION_FAILED);
    } finally {
      setIsCreateRoomLoading(false);
    }
  };

  const handleClickJoinRoom = async () => {
    if (!validatePlayerName()) {
      notifyError(errorMessages.INVALID_PLAYER_NAME);
      return;
    }

    if (!isAuthenticated && !isTempUserAuthenticated()) {
      setIsCreatePlayerLoading(true);
      try {
        const response = await SessionManager.createPlayerLogin(playerName);
        setTempSession(response);
        navigate("/entrar-na-sala", { state: { playerName, userId: user?.id } });
      } catch (error) {
        notifyError(errorMessages.PLAYER_CREATION_FAILED);
      } finally {
        setIsCreatePlayerLoading(false);
      }
    } else {
      navigate("/entrar-na-sala", { state: { playerName, userId: user?.id } });
    }
  };

  return (
    <RoomOptionsContent
      handleClickCreateRoom={handleClickCreateRoom}
      handleClickJoinRoom={handleClickJoinRoom}
      playerName={playerName}
      setPlayerName={setPlayerName}
      isCreateRoomLoading={isCreateRoomLoading}
      isCreatePlayerLoading={isCreatePlayerLoading}
    />
  );
}
