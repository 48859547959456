import { useState } from "react";
import { useGameRoom } from "../../../../../providers/room";
import { useSocket } from "../../../../../providers/socket";
import {
  ADMIN_DISCONNECTION,
  PLAYER_DISCONNECTED,
  ROOM_CHANGES,
  SWITCH_ADMIN_PLAYER
} from "../../../../utils/SocketEvents";
import { messages } from "../utils/MoreButtonActionTexts";
import { useNotification } from "../../../../../providers/notify";
import { useNavigate } from "react-router-dom";

export const useAdminActionsButton = () => {
  const { socket, disconnectSocket } = useSocket();
  const { room, setRoom, leaveRoom, playersIn } = useGameRoom();
  const { notifyError, notifySuccess } = useNotification();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const buildMoveAdminAndLeaveInput = (newAdminId: string) => {
    return {
      id: room?.player.id,
      nextAdminPlayerId: newAdminId
    };
  };

  const buildMoveAdminInput = (newAdminId: string) => {
    return {
      currentAdmin: {
        id: room?.player.id
      },
      newAdmin: {
        id: newAdminId
      },
      seed: room?.seed
    };
  };

  const buildRemovePlayersInput = (players: string[]) => {
    return {
      ids: players,
      seed: room?.seed
    };
  };

  const handleConfirmMoveAdmin = (admin: string[]) => {
    try {
      const handleResponse = (response: any) => {
        socket?.off(ROOM_CHANGES, handleResponse);
        const { error } = response;
        if (!error) {
          setRoom((prevRoom) => {
            if (prevRoom) {
              return {
                ...prevRoom,
                player: {
                  ...prevRoom.player,
                  isAdmin: false
                }
              };
            }
            return prevRoom;
          });
          sessionStorage.setItem("room", JSON.stringify(room));
          notifySuccess(messages.MOVE_ADMIN_SUCCESS);
        } else {
          notifyError(messages.MOVE_ADMIN_ERROR);
        }
      };
      setIsLoading(true);
      socket?.on(ROOM_CHANGES, handleResponse);

      socket?.emit(SWITCH_ADMIN_PLAYER, buildMoveAdminInput(admin[0]));

      setTimeout(() => {
        socket?.off(ROOM_CHANGES, handleResponse);
        console.log(new Error("Timeout: No response received"));
      }, 30000);
    } finally {
      setOpen(false);
      setIsLoading(false);
    }
  };

  const handleMoveAdminAndLeave = (admin: string[]) => {
    const handleResponse = (response: any) => {
      socket?.off(ROOM_CHANGES, handleResponse);
      const { error } = response;
      if (error) {
        notifyError(messages.MOVE_ADMIN_ERROR);
      } else {
        notifySuccess(messages.MOVE_ADMIN_SUCCESS);
        handleExitGame();
      }
    };
    setIsLoading(true);
    socket?.on(ROOM_CHANGES, handleResponse);

    socket?.emit(ADMIN_DISCONNECTION, buildMoveAdminAndLeaveInput(admin[0]));

    setTimeout(() => {
      socket?.off(ROOM_CHANGES, handleResponse);
      console.log(new Error("Timeout: No response received"));
    }, 30000);
  };

  const handleRemovePlayers = (players: string[]) => {
    try {
      const handleResponse = (response: any) => {
        socket?.off(ROOM_CHANGES, handleResponse);
        const { error } = response;
        if (!error) {
          notifySuccess(messages.REMOVE_PLAYER_SUCCESS);
        } else {
          notifyError(messages.REMOVE_PLAYER_ERROR);
        }
      };
      setIsLoading(true);
      socket?.on(ROOM_CHANGES, handleResponse);

      socket?.emit(PLAYER_DISCONNECTED, buildRemovePlayersInput(players));

      setTimeout(() => {
        socket?.off(ROOM_CHANGES, handleResponse);
        console.log(new Error("Timeout: No response received"));
      }, 30000);
    } finally {
      setOpen(false);
      setIsLoading(false);
    }
  };

  const handleExitGame = () => {
    disconnectSocket();
    leaveRoom();
    setOpen(false);
    navigate("/opcoes-de-sala");
  };

  return {
    handleConfirmMoveAdmin,
    open,
    setOpen,
    handleExitGame,
    playersIn,
    room,
    handleRemovePlayers,
    handleMoveAdminAndLeave,
    isLoading
  };
};
