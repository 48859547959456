import React, { ReactElement } from "react";
import BaseLayout from "../../shared/components/BaseLayout";

function NotFoundContent(): ReactElement {
  return (
    <BaseLayout>
      <h1>Página não encontrada</h1>
      <p className="text-justify">
        Não foi possível encontrar a página que você estava procurando. Isso ocorre porque:
      </p>
      <p className="text-justify">
        - Há um erro no URL em seu navegador da web. Verifique o URL e tente novamente.
      </p>
      <p className="text-justify">- A página que você está procurando foi movida ou excluída.</p>
    </BaseLayout>
  );
}

export default NotFoundContent;
