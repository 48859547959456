import React, { createContext, useContext, ReactNode, useState } from "react";
import { io, Socket } from "socket.io-client";

interface SocketContextType {
  socket: Socket | null;
  initializeSocket: () => Promise<Socket>;
  disconnectSocket: () => void;
}

const SERVER_URL = process.env.REACT_APP_API_URL || "http://localhost:3001/api";

const getToken = (): string | null => {
  const token = sessionStorage.getItem("user");
  const tempToken = sessionStorage.getItem("tempUser");
  return token
    ? JSON.parse(token).accessToken
    : tempToken
    ? JSON.parse(tempToken).accessToken
    : null;
};

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const SocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  const initializeSocket = (): Promise<Socket> => {
    return new Promise((resolve, reject) => {
      if (!socket) {
        const token = getToken();
        const socketClient = io(SERVER_URL + '/socket', {
          path: "/api/socket/",
          auth: {
            token: token ? `Bearer ${token}` : ""
          }
        });

        socketClient.on("connected", () => {
          setSocket(socketClient);
          resolve(socketClient);
        });

        socketClient.on("connect_error", (error) => {
          reject(error);
        });
      } else {
        resolve(socket);
      }
    });
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  };

  return (
    <SocketContext.Provider value={{ socket, initializeSocket, disconnectSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): SocketContextType => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
