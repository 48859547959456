import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Select from "@mui/material/Select";
import { OutlinedInput } from "@mui/material";

export const StyledInput = styled(InputBase)(({ theme, error }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: `1px solid ${
      error ? theme.palette.error.main : theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843"
    }`,
    fontSize: 16,
    width: "100%",
    padding: "8px 8px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    fontFamily: ["Dosis, sans-serif"].join(","),
    "&:focus": {
      boxShadow: `${alpha(
        error ? theme.palette.error.main : theme.palette.primary.main,
        0.25
      )} 0 0 0 0.2rem`,
      borderColor: error ? theme.palette.error.main : theme.palette.primary.main
    }
  }
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "100%",
    padding: "8px 8px",
    borderRadius: 4,
    fontFamily: ["Dosis, sans-serif"].join(","),
    borderBottom: "none"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    borderColor: theme.palette.primary.main
  }
}));

export const StyledPasswordInput = styled(OutlinedInput)(({ theme, error }) => ({
  "&.MuiOutlinedInput-root": {
    fontFamily: ["Dosis, sans-serif"].join(","),
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "& fieldset": {
      backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
      border: `1px solid ${
        error ? theme.palette.error.main : theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843"
      }`
    },
    "&:hover fieldset": {
      borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843"
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${
        error ? theme.palette.error.main : theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843"
      }`,
      boxShadow: `${alpha(
        error ? theme.palette.error.main : theme.palette.primary.main,
        0.25
      )} 0 0 0 0.2rem`,
      borderColor: error ? theme.palette.error.main : theme.palette.primary.main
    },
    position: "relative"
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px 40px 8px 8px",
    borderRadius: 4,
    zIndex: 1
  },
  "& .MuiOutlinedInput-adornedEnd": {
    paddingRight: "8px"
  },
  "& .MuiInputAdornment-root": {
    zIndex: 2
  },
  "&.MuiOutlinedInput-disabled": {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.background.paper
  },
  "&.MuiOutlinedInput-error": {
    "& fieldset": {
      borderColor: theme.palette.error.main
    }
  }
}));

export const StyledTextField = styled(InputBase)(({ theme, error }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: `1px solid transparent`,
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    fontFamily: ["Dosis, sans-serif"].join(","),
    textAlign: "center",
    "&::placeholder": {
      color: "#A5A5A5",
      opacity: 1,
    },
    "&:focus": {
      boxShadow: `${alpha(
        error ? theme.palette.error.main : theme.palette.primary.main,
        0.25
      )} 0 0 0 0.2rem`,
      borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
    },
  },
}));
