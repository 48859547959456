import React, { ReactElement } from "react";
import { IS_IN_THE_ROOM, LEFT_THE_ROOM, MEMBERS_OUT, ROOM_MEMBERS } from "../../utils/LobbyTexts";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import BlockIcon from "@mui/icons-material/Block";
import Badge from "../../../../shared/components/Badge";
import { Player } from "../../../../DTOs/Player";

interface RoomPlayersManagerContentProps {
  playersIn: Player[];
  playersOut: Player[];
}

function RoomPlayersManagerContent({
  playersIn,
  playersOut
}: RoomPlayersManagerContentProps): ReactElement {
  return (
    <div className="flex flex-col bg-white w-full min-h-64 items-start justify-between m-5 p-4">
      <div>
        <p className="text-body-bold mb-2">{ROOM_MEMBERS}</p>
        {playersIn.length > 0 &&
          playersIn.map((e, index) => (
            <div key={index} className="flex flex-row items-center mb-1">
              <TaskAltIcon sx={{ color: "#2DC071" }} />
              <p>
                <span className="text-green-200 text-body-semibold ml-2">{e.playerName}</span>{" "}
                <span className="text-green-200 text-body-regular">{IS_IN_THE_ROOM}</span>
              </p>
              {e.isAdmin && <Badge text="admin" className="ml-4" />}
            </div>
          ))}
      </div>
      <div className="mt-4">
        <p className="text-body-bold mb-2">{MEMBERS_OUT}</p>
        {playersOut.length > 0 &&
          playersOut.map((e, index) => (
            <div key={index} className="flex flex-row items-center">
              <BlockIcon sx={{ color: "#E0232E" }} />
              <p>
                <span className="text-red-500 text-body-semibold ml-2">{e.playerName}</span>{" "}
                <span className="text-red-500 text-body-regular">{LEFT_THE_ROOM}</span>
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default RoomPlayersManagerContent;
