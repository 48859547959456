import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

function GenericLoader() {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box className="flex flex-row items-center justify-center h-screen">
      <p className="ml-3 text-white text-heading-1 font-semibold">
        Carregando{dots}
      </p>
    </Box>
  );
}

export default GenericLoader;
