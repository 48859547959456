import React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

interface MoreButtonContentProps {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  moreButtonActions: MoreButtonActions[];
}

type MoreButtonActions = {
  name: string;
  handleClick: () => void;
};

const MoreButtonContent: React.FC<MoreButtonContentProps> = ({
  anchorEl,
  handleClose,
  moreButtonActions
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "16px",
              backgroundColor: "#E73088",
              padding: "8px 0",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              border: "1px solid white"
            }
          }
        }}
      >
        <Box display="flex" flexDirection="column" sx={{ width: "200px" }}>
          {moreButtonActions.map((action, index) => (
            <Button
              key={index}
              sx={{
                justifyContent: "flex-start",
                color: "white",
                textTransform: "none",
                padding: "12px 16px",
                borderBottom:
                  index !== moreButtonActions.length - 1 ? "1px solid white" : undefined,
                borderRadius: index === moreButtonActions.length - 1 ? "0 0 16px 16px" : "0"
              }}
              onClick={() => {
                action.handleClick();
                handleClose();
              }}
            >
              {action.name}
            </Button>
          ))}
        </Box>
      </Popover>
    </div>
  );
};

export default MoreButtonContent;
