import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/auth";

interface PrivateRouteProps {
  allowedRole?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ allowedRole }) => {
  const { user, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } else if (allowedRole === "both") {
    return <Outlet />;
  } else if (user?.role === allowedRole) {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
