import React from "react";
import logoQuePerfil from "../../assets/brand/logo-que-perfil.svg";
import GoBackButton from "./Buttons/GoBackButton";
import MoreButton from "./Buttons/MoreButton";
import { useGameRoom } from "../../providers/room";
import LogoutButton from "./Buttons/LogoutButton";
import { useAuth } from "../../providers/auth";

const Header: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { room } = useGameRoom();
  return (
    <div className="w-full flex justify-between items-center p-4 bg-blue-100 z-50 h-16">
      {!room?.player && <GoBackButton color="bg-pink-500" />}
      <div className="flex-grow flex justify-center">
        <img src={logoQuePerfil} alt="header-logo" className="w-20 mt-2" />
      </div>
      {room?.player && <MoreButton />}
      {!room?.player && isAuthenticated && <LogoutButton color="bg-pink-500" />}
    </div>
  );
};

export default Header;
