import React from "react";
import OTPInput from "react-otp-input";
import "./OtpInput.css";

interface OtpInputProps {
  value: string;
  handleChange: (value: string) => void;
  handlePaste: (e: React.ClipboardEvent<HTMLDivElement>) => void;
}

const OtpInput = ({ value, handleChange, handlePaste }: OtpInputProps) => {
  return (
    <OTPInput
      value={value.toUpperCase()}
      onChange={(e) => handleChange(e)}
      onPaste={(e) => handlePaste(e)}
      numInputs={7}
      shouldAutoFocus
      renderInput={(props) => <input {...props} />}
      containerStyle="otp-container"
      inputStyle="otp-input"
    />
  );
};

export default OtpInput;
