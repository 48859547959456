import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import RoomOptions from "../pages/RoomOptions";
import PrivateRoute from "./PrivateRoute";
import EnterRoom from "../pages/EnterRoom";
import Lobby from "../pages/Lobby";
import Header from "../shared/components/Header";

const AppRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/" && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cadastro" element={<Register />} />
        <Route path="/opcoes-de-sala" element={<RoomOptions />} />
        <Route path="/entrar-na-sala" element={<EnterRoom />} />
        <Route path="/sala-de-espera" element={<Lobby />} />

        {/* Rotas Privadas */}
        <Route element={<PrivateRoute allowedRole="user" />}></Route>
      </Routes>
    </>
  );
};

const RoutesSistem: React.FC = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default RoutesSistem;
