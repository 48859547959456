import { Socket } from "socket.io-client";
import { JoinRoomRequest } from "../../../DTOs/Player";
import { useGameRoom } from "../../../providers/room";
import { useSocket } from "../../../providers/socket";
import { NEW_PLAYER, ROOM_CHANGES } from "../../../shared/utils/SocketEvents";

export const useEnterRoom = () => {
  const { socket, initializeSocket } = useSocket();
  const { updatePlayers, setRoom } = useGameRoom();

  const ensureSocketInitialized = (): Promise<Socket> => {
    return new Promise((resolve, reject) => {
      if (socket?.connected) {
        resolve(socket);
      } else {
        initializeSocket()
          .then((initializedSocket) => {
            if (initializedSocket?.connected) {
              resolve(initializedSocket);
            } else {
              reject(new Error("Failed to initialize socket"));
            }
          })
          .catch((error) => {
            reject(error);
          });

        setTimeout(() => {
          if (!socket?.connected) {
            reject(new Error("Timeout: Socket connection failed"));
          }
        }, 10000);
      }
    });
  };

  const joinRoom = async (player: JoinRoomRequest): Promise<any> => {
    try {
      const socketInitialized = await ensureSocketInitialized();
      return new Promise((resolve, reject) => {
        if (socketInitialized) {
          const handleResponse = (response: any) => {
            socketInitialized.off(ROOM_CHANGES, handleResponse);
            const { error } = response;
            if (!error) {
              const roomResponse = {
                id: response.id ?? "",
                player: response.currentPlayer,
                seed: response.seed
              };
              sessionStorage.setItem("room", JSON.stringify(roomResponse));
              setRoom(roomResponse);
              updatePlayers(response.roomPlayers, response.currentPlayer.id);
              resolve(response);
            } else reject(response);
          };

          socketInitialized.on(ROOM_CHANGES, handleResponse);

          socketInitialized.emit(NEW_PLAYER, player);

          setTimeout(() => {
            socketInitialized.off(ROOM_CHANGES, handleResponse);
            reject(new Error("Timeout: No response received"));
          }, 30000);
        }
      });
    } catch (error) {
      return Promise.reject(new Error("Failed to initialize socket"));
    }
  };

  return { joinRoom };
};
